import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise: Stripe | null;

const initializeStripe = async () => {
  if (!stripePromise) {
    const apiKey: string | undefined = process.env.REACT_APP_DEV_MODE === 'true' ? process.env.REACT_APP_STRIPE_TEST_PK : process.env.REACT_APP_STRIPE_PK;
    if (apiKey) {
      stripePromise = await loadStripe(apiKey!);
    }
  }
  return stripePromise;
};
export default initializeStripe;
