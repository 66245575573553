import * as firebase from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import firebaseConfig from "./firebase.config.json";

// If Firebase isn't already initialized, initialize it using the above credentials.
export const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
console.log("initialized firebase");
// export default { app, auth };
