import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import firebaseConfig from "./firebase.config.json";

// Firebase configuration variables loaded from environment variables

// If Firebase isn't already initialized, initialize it using the above credentials.
firebase.initializeApp(firebaseConfig);

export default firebase;
