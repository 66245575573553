import { auth, firestore } from "../firebaseClient";
import firebase from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import axios from "axios";
import { BROWSER_EXTENSION_ID } from "../utils/constants";

export async function isUser(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("auth.currentUser", auth.currentUser);
        resolve(true);
      } else {
        console.log("auth.currentUser", auth.currentUser);
        resolve(false);
      }
      unsubscribe();
    });
  });
}

export async function fetchUserSubscription(): Promise<string | undefined> {
  await auth.currentUser?.getIdToken(true);
  const firestore = firebase.firestore();
  const subscriptionId = await fetchSubscriptionId(auth.currentUser!.uid);
  const subscriptionsRef = firestore
    .collection("customers")
    .doc(auth.currentUser?.uid)
    .collection("subscriptions")
    .doc(subscriptionId);

  const subscription = await subscriptionsRef.get();
  const data = subscription.data();
  if (data) {
    if (data.canceled_at) {
      return undefined;
    }
    return data!.items[0].price.product.name;
  }
  return undefined;
}

export const fetchSubscriptionId = async (
  uid: string
): Promise<string | undefined> => {
  const firestore = firebase.firestore();
  const subscriptionsRef = firestore
    .collection("customers")
    .doc(uid)
    .collection("subscriptions");

  const subscription = await subscriptionsRef.get();

  if (!subscription.empty) {
    const data = subscription.docs[subscription.docs.length - 1];
    return data.id;
  } else {
    return undefined;
  }
};

export const signOut = async (): Promise<void> => {
  await firebase.auth().signOut();
};

export const fetchUserName = async (uid: string): Promise<string> => {
  const firestore = firebase.firestore();
  const usersCollectionRef = firestore.collection("users");
  const querySnapshot = await usersCollectionRef.where("uid", "==", uid).get();
  if (querySnapshot.size > 0) {
    const userDocSnapshot = querySnapshot.docs[0];
    const userData = userDocSnapshot.data();
    if (userData && userData.name) {
      return userData.name;
    }
  }
  return "";
};

export const docExists = async (uid: string): Promise<boolean> => {
  const firestore = firebase.firestore();
  const usersCollectionRef = firestore.collection("users");
  const querySnapshot = await usersCollectionRef.where("uid", "==", uid).get();
  if (querySnapshot.size > 0) {
    return true;
  } else {
    return false;
  }
};

export const sendTokenToChromeExtension = ({ extensionId, jwt }: any) => {
  chrome.runtime.sendMessage(extensionId, { jwt }, (response) => {
    if (!response.success) {
      console.log("error sending message", response);
      signOut();
      return response;
    }
    console.log("Sucesss ::: ", response.message);
  });
};

export const onLogin = async (user: any, name: string | null) => {
  if (!(await docExists(user.uid))) {
    await addDoc(collection(firestore, "users"), {
      name: name ? name : user.displayName,
      email: user.email,
      uid: user.uid,
      provider: user.providerData[0].providerId,
    });
  }
  refreshExtensionToken(user)
};

export const refreshExtensionToken = async (user: any) => {
  const token = await user.getIdToken();
  if (!token) {
    signOut();
  }

  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/auth`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  sendTokenToChromeExtension({
    extensionId: BROWSER_EXTENSION_ID,
    jwt: response.data,
  });
}

export const checkBrowserExtInstalled = () => {
  return new Promise((resolve, reject) => {
    if (!chrome.hasOwnProperty("runtime")) {
      resolve(false);
    } else
      return chrome.runtime.sendMessage(
        BROWSER_EXTENSION_ID,
        { checkInstalled: true },
        (response) => {
          if (!response) {
            console.log("error sending message", response);
            resolve(false);
          }
          resolve(true);
        }
      );
  });
};

export const onPaymentReceived = async (uid: string, plan: string) => {
  const firestore = firebase.firestore();

  const usersCollection = await firestore
    .collection("users")
    .where("uid", "==", uid)
    .get();
  const userCollection = usersCollection.docs[0];
  const tmp = userCollection.data();
  tmp.paidPlan = plan;
  userCollection.ref.update(tmp);
  // Execute the query and get the matching documents
  // const querySnapshot = await getDoc(q);
  // const matchingDocs = querySnapshot.docs;
  // if (matchingDocs.length > 0) {
  //   const matchingDocRef = doc(usersCollection, matchingDocs[0].id);
  //   await updateDoc(matchingDocRef, { new_field: "new_value" });
  // }
};

export const checkPaidPlan = async (uid: string) => {
  const firestore = firebase.firestore();

  const usersCollection = await firestore
    .collection("users")
    .where("uid", "==", uid)
    .get();
  const userCollection = usersCollection.docs[0];
  const tmp = userCollection.data();
  if (tmp.paidPlan) {
    return tmp.paidPlan;
  } else {
    return null;
  }
};

export const setPaidPlan = async (uid: string, paidPlan: string | null) => {
  const firestore = firebase.firestore();

  const usersCollection = await firestore
    .collection("users")
    .where("uid", "==", uid)
    .get();
  const userCollection = usersCollection.docs[0];
  const userRef = userCollection.ref;

  await userRef.update({ paidPlan });

  return paidPlan;
};
